import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import _ from "lodash";

import './style.css';

import Config from '../../config';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "42px",
    zIndex:1,
    display: "flex",
  },
  active: {
    color:"#3bbdff"
  },
  link: {

  },
  nav: {
    width: "100%",
  },
  icon: {
    width: "120px",
  },
  img30: {
    width: "24px",
    padding: "2px",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [burgButton, setBurgButton] = useState(false);



  const closeBurger = () => {
    setBurgButton(false);
  }
  

  
  return (
    <header className={classes.root}>
        <nav className={classes.nav}>
            <ul>
                <li><Link className={ location.pathname =='/' ? classes.active : classes.link} exact to="/">Home</Link></li>
                <li><Link className={ location.pathname =='/about' ? classes.active : classes.link} exact to="/about">About</Link></li>
                <li><Link className={ location.pathname =='/terminal' ? classes.active : classes.link} exact to="/terminal">Terminal</Link></li>
            </ul>
        </nav>
        <div className={classes.icon}>
          <a  href="https://pump.fun/coin/FjXKhUU5Xx1PUD2ioTy7wPF6roKBdejdxdnm61kPpump" target="_blank" >
           <img className={classes.img30} src="/img/pumpfun.webp" />
          </a>
          <a  href="https://t.me/infinite4chanrooms" target="_blank" >
           <img className={classes.img30} src="/img/tgnotify.png" />
          </a>
          <a  href="https://t.me/HvaldimirAI" target="_blank" >
           <img className={classes.img30} src="/img/tg.png" />
          </a>
          <a  href="https://x.com/HvaldimirAI" target="_blank" >
           <img className={classes.img30} src="/img/x.webp" />
          </a>
        </div>
    </header>
  );
}

export default Header;
