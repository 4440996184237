import React from "react";
import { makeStyles } from '@mui/styles';
import { t, setStore } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "62px",
  },

}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <></>

  );
}

export default Footer;
