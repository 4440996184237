import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore, t } from "../../utils/utils";
import { toast } from 'react-toastify';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import Config from '../../config';
import _ from "lodash";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "calc(100vh - 61px);",
    justifyContent: "center",
  },
  title: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  leftblock: {
    textAlign: "center",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    overflow: "hidden",
    height: "100%",
  },
  img: {
    height: "calc(100vh - 500px)",
    margin: "auto",

  },
  btn: {
    backgroundColor: "#00ff00",
    color: "#020081",
    padding: "6px 20px",
    textDecoration: "none",
    borderRadius: "5px",
    margin: "10px auto",
    display: "block",
    width: "200px",
    margin: "auto",
    marginTop: "28px",
    fontWeight: "700",
  },
  user: {
    color:"#5f6fff",
  },
  hvaldimir: {
    color:"#00ff00",
  },
  time: {
    color:"#3bbdff",
    fontWeight: "bold",
  },
  padleft:{
    paddingLeft: "25px",
  },
  forurl:{color: "lightblue"},
  h1:{
    color: "#000",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "700",
    letterSpacing: "0.00px",
    lineHeight: "normal",
  },
  riba: {
    backgroundColor: "#ffffff4d",
    borderRadius: "7px",
    height: "100px",
    width: "100%",
  },
  
  main: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    backgroundColor: "initial",
    position: "relative",
    transition: "all .2s",
    justifyContent: "space-around",
  },
  news: {
    display: "flex",
    width:"50%",
    margin: "0 auto",
    zIndex: 1,
    position: "relative",
  },
  vids: {
    display: "flex",
    width:"80%",
    margin: "0 auto",
    zIndex: 1,
    position: "relative",
  },
  img30: {
    width:"100%",
    padding: "20px",
  },
  img302: {
    width:"100%",
    padding: "20px 35px",
  },
  vids30: {
    width:"100%",
    minWidth: "200px",
    padding: "20px",
  },
  aboutblock:{
    
  },
}));

const About = (props) => {
  const classes = useStyles();
  const { modal } = useSelector(getData);
  const navigate = useHistory();
  const [audio] = useState(new Audio("/music/whale.mp3"));

  useEffect(() => {
      try {
        audio.play();
      } catch (error) {
        audio.play();
      }
      
      audio.loop = true;
      return () => {
        audio.pause();
      };
  }, []);
  
  return (
    <section id="about" className="about">
        <div className={classes.leftblock}>

          <div className={classes.main}>
            <div className="mainimg"></div>


            
            <h1 className={classes.h1}>As seen on fake news:</h1>
            <div className={classes.aboutblock}>
              <div className={classes.news}>
                <a href="https://www.bbc.com/news/articles/cg4y7gvde5eo" target="_blank"><img className={classes.img30} src="/img/news/bbc-original.svg" /></a>
                <a href="https://www.yahoo.com/news/death-russian-spy-whale-hvaldimir-133011135.html" target="_blank"><img className={classes.img30} src="/img/news/yahoo--news-stacked-dark.svg" /></a>
                <a href="https://www.telegraph.co.uk/world-news/2024/09/01/russian-spy-whale-dead-norway-hvaldimir-beluga/" target="_blank"><img className={classes.img30} src="/img/news/the-telegraph-colour3.svg" /></a>
              </div>
              <div className={classes.news}>
                <a href="https://news.sky.com/story/hvaldimir-russian-spy-whale-found-dead-in-norway-13207880" target="_blank"><img className={classes.img302} src="/img/news/sky-news-dark-false.svg" /></a>
                <a href="https://www.forbes.com/sites/lesliekatz/2024/09/04/beluga-whale-accused-of-spying-for-russia-may-have-been-shot-to-death/" target="_blank"><img className={classes.img302} src="/img/news/forbes-original.png" /></a>
                <a href="https://www.itv.com/news/2023-05-30/russian-spy-beluga-whale-spotted-off-the-coast-of-sweden" target="_blank"><img className={classes.img302} src="/img/news/itv-original.svg" /></a>
              </div>
              
              <div className={classes.vids}>

                <iframe
                   className={classes.vids30}
                    src='https://www.youtube.com/embed/tLTSDx8lFfo'
                    frameborder='0'
                    allow='autoplay; encrypted-media'
                    allowfullscreen
                    title='Is this whale a Russian spy? - BBC News'
                />
                <iframe
                   className={classes.vids30}
                    src='https://www.youtube.com/embed/sONPREhCE-4'
                    frameborder='0'
                    allow='autoplay; encrypted-media'
                    allowfullscreen
                    title="Russian 'spy whale' Hvaldimir found dead near Norway | The World"
                />
                <iframe
                  className={classes.vids30}
                    src='https://www.youtube.com/embed/NQ3sAIEg6OY'
                    frameborder='0'
                    allow='autoplay; encrypted-media'
                    allowfullscreen
                    title="Beluga whale filmed playing 'fetch' with Rugby World Cup ball"
                />
              </div>
            </div>


          
          </div>
        </div>
    </section>
  )
}

export default withRouter(About);
