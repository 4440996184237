import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore, t } from "../../utils/utils";
import { keytomap } from "../../utils/mapping";
import { toast } from 'react-toastify';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import Config from '../../config';
import _ from "lodash";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "calc(100vh - 61px);",
    justifyContent: "center",
  },
  title: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  img: {
    maxWidth: "100%",
  },
  terminal: {
      backgroundColor: "#00003c",
      borderRadius: "10px",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      zIndex: 1,
      transition: "all 0.2s",
  },
  user: {
    color:"#5f6fff",
  },
  hvaldimir: {
    color:"#00ff00",
  },
  yellow: {
    color:"#FFD050",
  },
  blue: {
    color:"#5f6fff",
  },
  time: {
    color:"#fff",
    fontWeight: "bold",
  },
  padleft:{
    paddingLeft: "25px",
  },
  pad:{
    lineHeight: "3em",
  },
  padlefttitle:{
    paddingLeft: "25px",
    lineHeight: "3em",
  },
  forurl:{color: "#fff"},
  status:{
      width: "250px",
      minWidth: "250px",
      textAlign: "center",
    padding: "20px",
  },
  chatstatus:{
    fontSize: "14px",
    padding: "20px",
    overflowY: "auto",
  },
}));

const Terminal = (props) => {
  const classes = useStyles();
  const tredots = <><span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></>
  const { modal } = useSelector(getData);
  const navigate = useHistory();
  const [terminalmes, setTerminalmes] = useState([]);
  const [activetime, setActivetime] = useState(tredots);
  const [audio] = useState(new Audio("/music/The-Bog-of-Eternal-Stink.mp3"));

  useEffect(() => {
      try {
        audio.play();
      } catch (error) {
        audio.play();
      }
      
      audio.loop = true;
      return () => {
        audio.pause();
      };
  }, []);

  useEffect(() => {
    var now = new Date();
    setActivetime(now.toLocaleString());
    var loc = window.location, new_uri;
    if (loc.protocol === "https:") {
        new_uri = "wss:";
    } else {
        new_uri = "ws:";
    }
    new_uri += "//" + loc.host + "/ws";
    const ws = new WebSocket(
      new_uri
    );

    ws.onopen = () => {
      console.log("Connection Established!");
    };
    ws.onmessage = (event) => {
      var d = new Date();
      setActivetime(d.toLocaleString());
      const response = JSON.parse(event.data);
      if (response.type && response.type == 'terminal') {
        setTerminalmes((prevState) => [...prevState, response])
      }
      //this.termEndRef.current?.scrollIntoView({ behavior: 'smooth' })
      //ws.close();
    };
    ws.onclose = () => {
      console.log("Connection Closed!");
      //initWebsocket();
    };

    ws.onerror = () => {
      console.log("WS Error");
    };

    return () => {
      ws.close();
    };
  }, []);
  
  const renderMMBs = (messages,dic) => {
    return (
      messages.map((item, i)=>{
          return (
            <>
              <p  className={classes.padlefttitle}><span className={classes.blue}>{item}</span></p>
              <p  className={classes.padleft}>{dic[item].toString()}</p>
            </>
          )}))
  }  
  const renderMMs = (messages,dic) => {
    return (
      messages.map((item, i)=>{
          return (
            <>
              <p  className={classes.pad}><span className={classes.yellow}>{item}</span></p>
              {renderMMBs(keytomap(dic[item]),dic[item])}
            </>
          )}))
  }

  const renderTRMs = (messages) => {
    return (
      messages.slice().reverse().map((item, i)=>{
        if (item.post) {
          return (
            <>
              <p className={classes.pad}>
                <span className={classes.yellow}>{item.id}</span>
                <span className={classes.time}>{item.time}</span>
              </p>

              { renderMMs(keytomap(item.content),item.content) }

            </>
          )
        } else  if (item.img) {
          return (
            <>
              <p className={classes.pad}>><span className={classes.yellow}>{item.id}</span> <span className={classes.time}>{item.time}</span></p>
              <p  className={classes.padlefttitle}><span className={classes.blue}>What is in my head right now:</span></p>
              <p  className={classes.padleft}>{item.content}</p>
              <p  className={classes.padlefttitle}><span className={classes.blue}>What should be in the picture:</span></p>
              <p  className={classes.padleft}>{item.img}</p>
            </>
          )
        } else  if (item.url) {
          return (
            <>
              <p className={classes.pad}>><span className={classes.yellow}>{item.id}</span> <span className={classes.time}>{item.time}</span></p>
              <p  className={classes.padleft}><a className={classes.forurl} href={item.url} target="_blank">{item.content}</a></p>
            </>
          )
        } else {
          return (
            <>
              <p className={classes.pad}>
                <span className={classes.yellow}>{item.id}</span>
                <span className={classes.time}>{item.time}</span>
              </p>
              <p  className={classes.padleft}>{item.content}</p>
            </>
          )
        }
      })
    )
  }
  

  return (
    <section className={classes.root}>
        <div className={classes.terminal}>
            <div className="terminal-header">
                <div className="buttons">
                    <span className="red"></span>
                    <span className="yellow"></span>
                    <span className="green"></span>
                </div>
                <span className="terminal-title">hvaldimir@neural_network:~</span>
            </div>
            <div className="bigterminal-body">
                <div className={classes.status}>
                   <p className={classes.yellow}>SYSTEM_STATUS_INDICATOR:</p>
                   <p className={classes.yellow}>ACTIVE</p>
                   <p className={classes.yellow}> </p>
                   <p className={classes.blue}>Last Check-In:</p><p className={classes.blue}>{activetime}</p>
                </div>
                <div className={classes.chatstatus}>
                    { renderTRMs(terminalmes) }
                </div>

            </div>
        </div>


    </section>
  )
}

export default withRouter(Terminal);
